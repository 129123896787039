import React, { createContext, useContext, useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import api from '../services/api';

interface IValidacao {
  id_validacao: number;
  pes_codigo: string;
  email: string;
  codigo_validacao: string;
  status: string;
}

interface ICivil {
  nome: string;
  matricula: string;
  opm_nome: string;
  email: string;
  validacao: IValidacao;
}

interface IResponseCreateValidacao {
  id_validacao: number;
  pes_codigo: string;
  email: string;
  codigo_validacao: string;
  status: string;
}

interface ICivilContextData {
  civil: ICivil | undefined;
  loadCivil(civilDados: ICivil): void;
  createLinkAssinatura(id_validacao: number): Promise<any>;
  createValidacao(email: string): Promise<IResponseCreateValidacao | undefined>;
  finalizaValidacao(codigo_validacao: string): Promise<boolean>;
  cancelValidation(id_validacao: number): Promise<void>;
}

const CivilContext = createContext<ICivilContextData>({} as ICivilContextData);

export const CivilProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const [civil, setCivil] = useState<ICivil | undefined>(undefined);

  const loadCivil = useCallback((civilDados: ICivil): void => {
    setCivil(civilDados);
  }, []);

  const createLinkAssinatura = useCallback(
    async (id_validacao: number): Promise<any> => {
      try {
        const response = await api.post('assinaturas/links', {
          id_validacao,
        });

        toast({
          title: 'Sucesso!',
          description: 'Link de cadastro de assinatura enviado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return response.data;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast],
  );

  const createValidacao = useCallback(
    async (email: string): Promise<IResponseCreateValidacao | undefined> => {
      try {
        const validacao = await api.post('validacoes', {
          email,
          pes_codigo: civil?.matricula,
          name: civil?.nome,
        });

        if (civil) {
          setCivil({
            ...civil,
            validacao: validacao.data,
          });
        }
        toast({
          title: 'Sucesso!',
          description: 'Um email foi enviado com um pin de validação!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return validacao.data;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast, civil],
  );

  const finalizaValidacao = useCallback(
    async (codigo_validacao: string): Promise<boolean> => {
      try {
        await api.put(`validacoes/${civil?.validacao.id_validacao}`, {
          codigo_validacao,
        });

        if (civil) {
          setCivil({
            ...civil,
            validacao: { ...civil.validacao, status: '2' },
          });
        }
        return true;
      } catch (error) {
        toast({
          title: 'Ocorreu um erro.',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return false;
      }
    },
    [toast, civil],
  );

  const cancelValidation = useCallback(async (): Promise<void> => {
    try {
      await api.put(`validacoes/${civil?.validacao.id_validacao}`);

      if (civil) {
        setCivil({
          ...civil,
          validacao: civil.validacao,
        });
      }

      toast({
        title: 'Sucesso!',
        description: 'Validação cancelada com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [toast, civil]);

  return (
    <CivilContext.Provider
      value={{
        civil,
        loadCivil,
        createValidacao,
        finalizaValidacao,
        cancelValidation,
        createLinkAssinatura,
      }}
    >
      {children}
    </CivilContext.Provider>
  );
};

export function useCivil(): ICivilContextData {
  const context = useContext(CivilContext);

  if (!context) {
    throw new Error('useCivil precisa estar dentro de CivilContext provider');
  }
  return context;
}
