import React, { createContext, useContext, useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import api from '../services/api';

import IPessoa from '../interfaces/IPessoa';

interface IResponseCreateEmail {
  pes_codigo_email: number;
  pes_email: string;
}

interface IResponseCreateValidacao {
  id_validacao: number;
  pes_codigo: string;
  email: string;
  codigo_validacao: string;
  status: string;
}

interface IEndereco {
  pes_codigo_endereco: number;
  pes_codigo: number;
  pes_tipo_endereco: string;
  pes_situacao_endereco: string;
  pes_cep: string;
  pes_endereco: string;
  pes_endereco_num: string;
  pes_endereco_complemento: string;
  pes_bairro: string;
  pes_cidade: string;
  pes_estado: string;
}

interface IPessoaContextData {
  pessoa: IPessoa | undefined;
  loadingImage: boolean;
  pessoaSelecionado: string;
  policialImage: string | undefined;
  setPessoaSelecionada(matricula: string): void;
  loadPessoa(matricula: string): Promise<void>;
  loadImage(matricula: string): Promise<void>;
  updateImage(image: string): Promise<void>;
  createEmail(pes_email: string): Promise<any>;
  createTelefone(pes_fone: string, pes_tipo_fone: string): Promise<any>;
  updateTelefone(
    pes_codigo_fone: number,
    pes_fone: string,
    pes_tipo_fone: string,
  ): Promise<any>;
  updateEndereco(id: number, endereco: IEndereco): Promise<any>;
  createEndereco(endereco: IEndereco): Promise<any>;
  deleteTelefone(id: number): Promise<any>;
  createLinkAssinatura(id_validacao: number): Promise<any>;
  deleteEmail(id: number): Promise<any>;
  createValidacao(
    pes_email: string,
  ): Promise<IResponseCreateValidacao | undefined>;
  finalizaValidacao(
    id_validacao: number,
    codigo_validacao: string,
  ): Promise<boolean>;
  cancelValidation(id_validacao: number): Promise<void>;
  revogaAssinatura(id_validacao: number): Promise<void>;
}

const PessoaContext = createContext<IPessoaContextData>(
  {} as IPessoaContextData,
);

export const PessoaProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const [pessoa, setPessoa] = useState<IPessoa | undefined>(undefined);
  const [loadingImage, setLoadingImage] = useState(false);
  const [pessoaSelecionado, setPessoaSelecionada] = useState('');

  const [policialImage, setPolicialImage] = useState<string | undefined>(
    undefined,
  );

  const loadImage = useCallback(
    async (matricula: string): Promise<void> => {
      try {
        const { data } = await api.get(`pessoas/${matricula}/images`);

        setPolicialImage(`data:image/jpeg;base64,${data}`);
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const loadPessoa = useCallback(
    async (matricula: string): Promise<void> => {
      try {
        setPessoa(undefined);
        const { data } = await api.get(`pessoas/${matricula}`);
        setPessoa(data);
        if (data.militar) {
          setLoadingImage(true);
          await loadImage(matricula);
          setLoadingImage(false);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const updateImage = useCallback(async (image: string): Promise<void> => {
    setPolicialImage(image);
  }, []);

  const createEmail = useCallback(
    async (pes_email: string): Promise<IResponseCreateEmail | undefined> => {
      try {
        const response = await api.post('pessoas/emails', {
          pes_email,
          pes_codigo: pessoa?.pes_codigo,
        });

        const newEmail = {
          pes_email: response.data.pes_email,
          pes_codigo_email: response.data.pes_codigo_email,
        };

        if (pessoa) {
          setPessoa({ ...pessoa, emails: [...pessoa.emails, newEmail] });
        }

        toast({
          title: 'Sucesso!',
          description: 'Email cadastrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao cadastrar o email!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast, pessoa],
  );

  const createTelefone = useCallback(
    async (
      pes_fone: string,
      pes_tipo_fone: string,
    ): Promise<IResponseCreateEmail | undefined> => {
      try {
        const response = await api.post('pessoas/telefones', {
          pes_fone,
          pes_codigo: pessoa?.pes_codigo,
          pes_tipo_fone,
        });

        if (pessoa) {
          setPessoa({
            ...pessoa,
            telefones: [...pessoa.telefones, response.data],
          });
        }
        toast({
          title: 'Sucesso!',
          description: 'Telefone cadastrado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao cadastrar o telefone',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast, pessoa],
  );

  const createLinkAssinatura = useCallback(
    async (id_validacao: number): Promise<any> => {
      try {
        const response = await api.post('assinaturas/links', {
          id_validacao,
        });

        toast({
          title: 'Sucesso!',
          description: 'Link de cadastro de assinatura enviado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao enviar o email!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast],
  );

  const createValidacao = useCallback(
    async (email: string): Promise<IResponseCreateValidacao | undefined> => {
      try {
        const validacao = await api.post('validacoes', {
          email,
          militar: pessoa?.militar,
          pes_codigo: pessoa?.pes_codigo,
        });

        if (pessoa) {
          setPessoa({
            ...pessoa,
            validacoes: [...pessoa?.validacoes, validacao.data],
          });
        }
        toast({
          title: 'Sucesso!',
          description: 'Um email foi enviado com um pin de validação!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return validacao.data;
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao enviar o email!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return undefined;
      }
    },
    [toast, pessoa],
  );

  const finalizaValidacao = useCallback(
    async (
      id_validacao: number,
      codigo_validacao: string,
    ): Promise<boolean> => {
      try {
        await api.put(`validacoes/${id_validacao}`, {
          codigo_validacao: String(codigo_validacao),
        });

        const validacaoAtiva = pessoa?.validacoes.find(
          (validacao) => validacao.id_validacao === id_validacao,
        );

        if (pessoa && validacaoAtiva) {
          setPessoa({
            ...pessoa,
            validacoes: [{ ...validacaoAtiva, status: '2' }],
          });
        }
        return true;
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao finalizar a validação!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return false;
      }
    },
    [toast, pessoa],
  );

  const cancelValidation = useCallback(
    async (id_validacao: number): Promise<void> => {
      try {
        await api.put(`validacoes/revoga/${id_validacao}`);

        const validacoesAtivas = pessoa?.validacoes.filter(
          (validacao) => validacao.id_validacao !== id_validacao,
        );

        if (pessoa && validacoesAtivas) {
          setPessoa({
            ...pessoa,
            validacoes: [...validacoesAtivas],
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Validação cancelada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao cancelar a validação!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const revogaAssinatura = useCallback(
    async (id_validacao: number): Promise<void> => {
      try {
        await api.put(`assinaturas/revoga/completo`, { id_validacao });

        const validacoesAtivas = pessoa?.validacoes.filter(
          (validacao) => validacao.id_validacao !== id_validacao,
        );

        if (pessoa && validacoesAtivas) {
          setPessoa({
            ...pessoa,
            validacoes: [...validacoesAtivas],
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Assinatura e Validação cancelada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao revogar a validação',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const deleteEmail = useCallback(
    async (pes_codigo_email: number): Promise<void> => {
      try {
        await api.delete(`pessoas/emails/${pes_codigo_email}`);

        const emailsFiltered = pessoa?.emails.filter(
          (email) => email.pes_codigo_email !== pes_codigo_email,
        );

        if (pessoa && emailsFiltered) {
          setPessoa({
            ...pessoa,
            emails: emailsFiltered,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Email deletado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao deletar o email!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const deleteTelefone = useCallback(
    async (pes_codigo_fone: number): Promise<void> => {
      try {
        await api.delete(`pessoas/telefones/${pes_codigo_fone}`);

        const telefonesFiltered = pessoa?.telefones.filter(
          (telefone) => telefone.pes_codigo_fone !== pes_codigo_fone,
        );

        if (pessoa && telefonesFiltered) {
          setPessoa({
            ...pessoa,
            telefones: telefonesFiltered,
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Telefone deletado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao deletar o telefone!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const updateTelefone = useCallback(
    async (
      pes_codigo_fone: number,
      pes_fone: string,
      pes_tipo_fone: string,
    ): Promise<void> => {
      try {
        const telefone = await api.put(`pessoas/telefones/${pes_codigo_fone}`, {
          pes_fone,
          pes_tipo_fone,
        });

        const telefonesFiltered = pessoa?.telefones.filter(
          (fone) => fone.pes_codigo_fone !== pes_codigo_fone,
        );

        if (pessoa && telefonesFiltered) {
          setPessoa({
            ...pessoa,
            telefones: [...telefonesFiltered, telefone.data],
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Telefone atualizado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao atualizar o telefone!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const updateEndereco = useCallback(
    async (id: number, endereco: IEndereco): Promise<void> => {
      try {
        const enderecoUpdated = await api.put(
          `pessoas/enderecos/${id}`,
          endereco,
        );

        const enderecosFilter = pessoa
          ? pessoa.enderecos.filter((end) => end.pes_codigo_endereco !== id)
          : [];

        if (pessoa && enderecoUpdated) {
          setPessoa({
            ...pessoa,
            enderecos: [...enderecosFilter, enderecoUpdated.data],
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Endereço atualizado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao atualizar o endereço!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  const createEndereco = useCallback(
    async (endereco: IEndereco): Promise<void> => {
      try {
        const enderecoCreated = await api.post(`pessoas/enderecos/`, {
          ...endereco,
          pes_codigo: pessoa?.pes_codigo,
        });

        if (pessoa && enderecoCreated) {
          setPessoa({
            ...pessoa,
            enderecos: [...pessoa.enderecos, enderecoCreated.data],
          });
        }

        toast({
          title: 'Sucesso!',
          description: 'Endereço adicionado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const err = error as AxiosError;
        toast({
          title: 'Ocorreu um erro.',
          description: err.response
            ? err.response.data.message
            : 'Ocorreu um erro ao adicionar o endereço!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, pessoa],
  );

  return (
    <PessoaContext.Provider
      value={{
        pessoa,
        pessoaSelecionado,
        setPessoaSelecionada,
        policialImage,
        loadPessoa,
        loadImage,
        updateImage,
        createEmail,
        createTelefone,
        updateTelefone,
        updateEndereco,
        createEndereco,
        deleteTelefone,
        createValidacao,
        finalizaValidacao,
        deleteEmail,
        cancelValidation,
        revogaAssinatura,
        createLinkAssinatura,
        loadingImage,
      }}
    >
      {children}
    </PessoaContext.Provider>
  );
};

export function usePessoa(): IPessoaContextData {
  const context = useContext(PessoaContext);

  if (!context) {
    throw new Error('usePessoa precisa estar dentro de PessoaContext provider');
  }
  return context;
}
