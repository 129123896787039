import React from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

import { Container } from './styles';

interface ILayoutProps {
  title?: string;
  sigla?: string;
}
const DefaultLayout: React.FC<ILayoutProps> = ({
  children,
  title = process.env.REACT_APP_TITLE,
  sigla = process.env.REACT_APP_SIGLA,
}) => {
  const [maiorQue400px] = useMediaQuery('(min-width: 480px)');
  return (
    <Flex minHeight="100vh" direction="column">
      <Header
        title={title || 'ADICIONE O TÍTULO EM .ENV NA VARIÁVEL REACT_APP_TITLE'}
        sigla={sigla || ''}
      />
      <Container>{children}</Container>
      {maiorQue400px && <Footer />}
    </Flex>
  );
};

export default DefaultLayout;
