import React from 'react';
import { MdHelp, MdSearch, MdDashboard, MdMail } from 'react-icons/md';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';

import Logo from './Logo';
import MenuItem from './Menu';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  isOpen: boolean;
  onClose(): void;
}

const SideBarMobile: React.FC<ISideBarProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  return (
    <Drawer
      placement="left"
      onClose={onClose}
      isOpen={isOpen}
      size="xs"
      isFullHeight
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <HStack justifyContent="center">
              <DrawerCloseButton />
              <Logo />
            </HStack>
          </DrawerHeader>
          <DrawerBody bg="green.500" p={0}>
            {user.currentPerfil === 'SGA - CETIC' && (
              <>
                <MenuItem
                  to="/home"
                  label="INICIAL"
                  icon={MdDashboard}
                  onClose={onClose}
                />

                <MenuItem
                  to="/validacoes"
                  label="CONSULTA"
                  icon={MdSearch}
                  onClose={onClose}
                />
                <MenuItem
                  toExternal={`${process.env.REACT_APP_URL_API}/public/files/47fd23b1-b6c5-4dd9-a40c-9683a472aeb5-manual-sga.pdf`}
                  label="MANUAL"
                  icon={MdHelp}
                  onClose={onClose}
                />
              </>
            )}
            <MenuItem
              to="/email/institucional/criar"
              label="E-MAIL INSTITUCIONAL"
              icon={MdMail}
              onClose={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SideBarMobile;
