import axios from 'axios';

// eslint-disable-next-line

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
// if (error.response?.status === 401) {
//   return error.response.status;
// }
// return Promise.reject(error);

// if (error.response.data.status === 'error') {
//   return error.response;
// }

//   return Promise.reject(error.response);
// },
// );

export default api;
