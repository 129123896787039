import React from 'react';
import { MdEdit, MdHelp, MdSearch, MdDashboard, MdMail } from 'react-icons/md';
import {
  Accordion,
  Center,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAuth } from '../../../../contexts/auth';
import Logo from './Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';
import LogoCetic from '../../../../assets/logo-cetic-35px.png';
import { Container, HeaderMenu, Footer } from './styles';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const ajudaItens = [
  {
    label: 'Manual',
    toExternal: `${process.env.REACT_APP_URL_API}/public/files/47fd23b1-b6c5-4dd9-a40c-9683a472aeb5-manual-sga.pdf`,
  },
];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();
  const bg = useColorModeValue('green.500', '#5b5b58');
  const color = useColorModeValue('gray.500', 'white');
  // const colorHover = useColorModeValue('white', 'gray.500');
  return (
    <Container activated={activated}>
      <HeaderMenu activated={activated}>
        <Logo activated={activated} />
      </HeaderMenu>

      <Flex
        bg={bg}
        color={color}
        direction="column"
        alignItems="initial"
        flex="1"
      >
        <Accordion allowToggle>
          {user.currentPerfil === 'SGA - CETIC' && (
            <>
              <MenuItem
                to="/home"
                label="INICIAL"
                icon={MdDashboard}
                activated={activated}
              />

              <MenuItem
                to="/validacoes"
                label="CONSULTA"
                icon={MdSearch}
                activated={activated}
              />

              <MenuItem
                toExternal={`${process.env.REACT_APP_URL_API}/public/files/47fd23b1-b6c5-4dd9-a40c-9683a472aeb5-manual-sga.pdf`}
                label="MANUAL"
                icon={MdHelp}
                activated={activated}
              />
            </>
          )}
          <MenuItem
            to="/email/institucional/criar"
            label="E-MAIL INSTITUCIONAL"
            icon={MdMail}
            activated={activated}
          />
        </Accordion>
      </Flex>
      <Footer activated={activated}>
        <Center w="100%">
          {activated && <Image src={LogoCetic} alt="logo cetic" />}
        </Center>
      </Footer>
    </Container>
  );
};

export default SideBar;
