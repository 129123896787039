import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

const SignIn = lazy(
  () =>
    import(
      /* webpackChunkName: "signin" */
      '../pages/SignIn'
    ),
);

const Reset = lazy(
  () =>
    import(
      /* webpackChunkName: "reset" */
      '../pages/Reset'
    ),
);

const Forgot = lazy(
  () =>
    import(
      /* webpackChunkName: "forgot" */
      '../pages/Forgot'
    ),
);

const EsqueciSenhaEmail = lazy(
  () =>
    import(
      /* webpackChunkName: "esqueci-senha-email" */
      '../pages/EsqueciSenhaEmail'
    ),
);

const ResetarEmail = lazy(
  () =>
    import(
      /* webpackChunkName: "resetar-email" */
      '../pages/ResetarEmail'
    ),
);

const EmailInstitucional = lazy(
  () =>
    import(
      /* webpackChunkName: "email-institucional" */
      '../pages/EmailInstitucional'
    ),
);

const Assinatura = lazy(
  () =>
    import(
      /* webpackChunkName: "assinatura" */
      '../pages/Assinatura'
    ),
);

const Verificador = lazy(
  () =>
    import(
      /* webpackChunkName: "verificador" */
      '../pages/Verificador'
    ),
);

const Validacoes = lazy(
  () =>
    import(
      /* webpackChunkName: "validacoes" */
      '../pages/Validacoes'
    ),
);

const Validacao = lazy(
  () =>
    import(
      /* webpackChunkName: "validacao" */
      '../pages/Validacao'
    ),
);

const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "home" */
      '../pages/Home'
    ),
);

const Assinar = lazy(
  () =>
    import(
      /* webpackChunkName: "assinar" */
      '../pages/Assinar'
    ),
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route
        path="/assinaturas/:token"
        component={Assinatura}
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/verificador"
        component={Verificador}
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/assinar/:token"
        component={Assinar}
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/resetar"
        title="SISTEMA DE SEGURANÇA"
        sigla="SEG"
        component={Reset}
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/esqueci"
        component={Forgot}
        title="SISTEMA DE SEGURANÇA"
        sigla="SEG"
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/email/institucional/resetar"
        component={ResetarEmail}
        title="SISTEMA DE SEGURANÇA"
        sigla="SEG"
        isPublicPrivate
        hasRole={['*']}
      />
      <Route
        path="/email/institucional/esqueci"
        component={EsqueciSenhaEmail}
        title="SISTEMA DE SEGURANÇA"
        sigla="SEG"
        isPublicPrivate
        hasRole={['*']}
      />
      <Route path="/home" component={Home} isPrivate hasRole={['*']} />
      {/* <Route
        path="/email/institucional/criar"
        component={EmailInstitucional}
        isPrivate
        hasRole={['*']}
      /> */}
      <Route
        path="/validacao/"
        component={Validacao}
        isPrivate
        hasRole={['SGA - CETIC']}
      />
      <Route
        path="/validacoes"
        component={Validacoes}
        isPrivate
        hasRole={['SGA - CETIC']}
      />
      <Route />
    </Switch>
  );
};

export default Routes;
