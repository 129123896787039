import React from 'react';
import ReactDOM from 'react-dom';
// import { ColorModeScript } from '@chakra-ui/react';
// import theme from './theme';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    {/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
