import React from 'react';
import { PessoaProvider } from './PessoaContext';
import { CivilProvider } from './CivilContext';

import { AuthProvider } from './auth';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PessoaProvider>
      <CivilProvider>{children}</CivilProvider>
    </PessoaProvider>
  </AuthProvider>
);

export default AppProvider;
